import React from "react"
import { graphql } from "gatsby"
import {Layout} from '@components/layout/layout'

const OfertTemplate:React.FC = ({data}) => {
  return (<Layout
  data={data}
  prop={{
    canonicalUrl: 'https://www.mediroot.pl/oferta/',
    title: 'Oferta | Mediroot',
    description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>



  </Layout>);
};

export default OfertTemplate;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
